<template>
  <banner text="Screen-News"/>

  <sui-segment v-if="loadingScreenNews">
    <p>Screen-News werden geladen</p>
    <loader-local :active="true"/>
  </sui-segment>

  <sui-grid class="mt-0" v-if="screenNews && screenNews.length">
    <sui-grid-row :columns="2">
      <sui-grid-column style="padding-bottom: 1rem" v-for="(newsItem, index) in screenNews" :key="index">
        <sui-message :style="{ backgroundColor: newsItem.colors.background}">
          <sui-message-content>
            <sui-message-header :style="{ color: newsItem.colors.headline}">
              {{ newsItem.title }}
            </sui-message-header>
            <span :style="{ color: newsItem.colors.text}">
              {{ newsItem.text }}
            </span>
          </sui-message-content>
        </sui-message>
      </sui-grid-column>
    </sui-grid-row>
  </sui-grid>

  <sui-message v-else-if="!loadingScreenNews">
    <sui-message-content>
      <i>
        &empty; aktuell keine Einträge
      </i>
    </sui-message-content>
  </sui-message>

</template>

<script>
import Banner from "../components/Banner";
import LoaderLocal from "../components/LoaderLocal";
import store from "../store";

export default {
  name: "ScreenNews",
  components: {Banner, LoaderLocal},
  data: function () {
    return {
      screenNews: null,
      loadingScreenNews: false
    }
  },
  methods: {
    getScreenNews: function () {
      this.loadingScreenNews = true;

      store.getters['news/getScreenNews']
          .subscribe((screenNews) => {
            if (screenNews !== null) {
              this.screenNews = screenNews;
              this.loadingScreenNews = false;
            }
          }, (error) => {
            // 401 redirects to login anyway
            if (error.response.status !== 401) {
              alert('Fehler beim Laden der Screen-News.');
              this.loadingScreenNews = false;
            }
          });
    }
  },
  computed: {},
  created() {
    this.getScreenNews();
  }
}
</script>

<style scoped>
</style>