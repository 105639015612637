import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Chats from '../views/Chats.vue'
import Chat from '../views/Chat.vue'
import Login from "../views/Login";
import Stundenplan from "../views/Stundenplan";
import Termine from "../views/Termine";
import ScreenNews from "../views/ScreenNews";
import Unterricht from "../views/Unterricht";
import Hausaufgaben from "../views/Hausaufgaben";
import Profile from "../views/Profile";
import ChatRequest from "../views/ChatRequest";
import Hausaufgabe from "../views/Hausaufgabe";
import Notifications from "../views/Notifications";
import initialize from "../initialization/initialize";
import store from "../store";

const routes = [
    {
        path: '/login/:school?',
        name: 'Login',
        component: Login
    },
    {
        path: '/register/:school?',
        name: 'Register',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Register.vue')
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('../views/ResetPassword.vue')
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/chat',
        name: 'Chats',
        component: Chats
    },
    {
        path: '/chat/:chatId',
        name: 'Chat',
        component: Chat
    },
    {
        path: '/chat-request',
        name: 'ChatRequest',
        component: ChatRequest
    },
    {
        path: '/stundenplan',
        name: 'Stundenplan',
        component: Stundenplan
    },
    {
        path: '/screen-news',
        name: 'ScreenNews',
        component: ScreenNews
    },
    {
        path: '/unterricht',
        name: 'Unterricht',
        component: Unterricht
    },
    {
        path: '/hausaufgaben',
        name: 'Hausaufgaben',
        component: Hausaufgaben
    },
    {
        path: '/hausaufgaben/:homeworkId',
        name: 'Hausaufgabe',
        component: Hausaufgabe
    },
    {
        path: '/termine',
        name: 'Termine',
        component: Termine
    },
    {
        path: '/profile',
        name: 'Profil',
        component: Profile
    },
    {
        path: '/notifications',
        name: 'Benachrichtigungen',
        component: Notifications
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    Promise.resolve()
        .then(() => initialize(to))
        .then(() => {
            if (!store.getters['auth/check']) return;

            if (
                to.name === 'Register'
                || (to.name === 'Login' && store.state.auth.verified !== false)
            ) {
                return store.dispatch('auth/logout');
            }
        })
        .finally(() => next());
});

export default router
