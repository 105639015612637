import axios from 'axios';
import store from "../index"
import {BehaviorSubject} from "rxjs";

export default {
    namespaced: true,
    state: {
        news: new BehaviorSubject(null),
        screenNews: new BehaviorSubject(null)
    },
    getters: {
        getNews: (state) => {
            if (state.news.getValue() === null) {
                store.dispatch('news/fetchNews');
                store.commit('dataUpdates/subscribe', {
                    dataType: 'news',
                    callback: () => store.dispatch('news/fetchNews')
                })
            }
            return state.news;
        },
        getScreenNews: (state) => {
            if (state.screenNews.getValue() === null) {
                store.dispatch('news/fetchScreenNews');
                store.commit('dataUpdates/subscribe', {
                    dataType: 'screenNews',
                    callback: () => store.dispatch('news/fetchScreenNews')
                })
            }
            return state.screenNews;
        }
    },
    mutations: {},
    actions: {
        fetchNews({state, rootGetters}) {
            return axios.get(rootGetters.getUrl('/api/news'))
                .then(res => res.data)
                .then(news => state.news.next(news))
                .catch(console.log);
        },
        fetchScreenNews({state, rootGetters}) {
            return axios.get(rootGetters.getUrl('/api/screen-news'))
                .then(res => res.data)
                .then(news => state.screenNews.next(news))
                .catch(console.log);
        }
    },
    modules: {}
}
